@import '../../styles/mixins';
@import '../../styles/variables';

.otpVerifyBoxWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.didntGetCodeBox {
  width: 100%;
  margin-bottom: 25px;
  .didntGetCode {
    font-size: 12px;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #3c77c3;
    cursor: pointer;
    &:disabled {
      background: none;
      border: none;
      color: #8d8d8d;
      cursor: not-allowed;
    }
  }
}
.submitTwoFactor {
  border-radius: 13px;
  padding: 5px 15px 5px 15px;
  border: none;
  background: linear-gradient(to bottom right, $dimGrey, $yankeesBlue);
  color: $white;
  font-size: 18px;
  width: 40%;
  height: 40px;
  cursor: pointer;
}
