@import '../../../styles/mixins';
@import '../../../styles/variables';

.TimeoutModelContents {
  background-color: rgba(255, 255, 255, 0.746);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 30px 15px;
  margin: 15px;
  .modelContentTitle {
    font-size: 28px;
    margin: 5px 0 15px;
    color: $raisinBlack;
  }
  .modelContentData {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    p {
      margin: 0 0 15px;
      color: $raisinBlack;
    }
    h4 {
      margin: 0;
      font-weight: 500;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $raisinBlack;
      span {
        font-size: 32px;
        padding-right: 10px;
      }
    }
    .modalCloseButton {
      border-radius: 13px;
      padding: 5px 15px 5px 15px;
      border: none;
      background: linear-gradient(to bottom right, $dimGrey, $yankeesBlue);
      color: $white;
      font-size: 18px;
      margin-top: 15px;
      width: 100%;
      height: 40px;
      cursor: pointer;
    }
  }
}
