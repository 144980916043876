@import '../../../styles/mixins';
@import '../../../styles/variables';

.loaderBackdrop {
  background: rgba($color: #1d1d1d, $alpha: 0.86);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  .loader {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
  }
  .loaderSpin {
    svg{
      height: 48px;
      width: 48px;
      margin: auto;
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}