@import './mixins';
@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  background-image: url(../../bronzehatch.svg);
  background-size: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  @include respond-below(md) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}

.popUpOpen {
  overflow: hidden;
  height: 100%;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $charlestonGreen;
  box-shadow: 0 0 0px 1000px $white inset;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.masterProfileLayout {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 80px;
  width: 100%;
}

.welcomeHeaderContainer {
  width: calc(100% - 15px);
  height: auto;
  border-radius: 12px 12px 12px 12px;
  text-align: left;
  .welcomeHeader {
    color: $flashWhite;
    text-shadow: 1px 1px 4px $raisinBlack;
    font-weight: 400;
    font-size: 22px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    @include respond-below(md) {
      font-size: 18px;
    }
  }
}

@keyframes scale {
  0% {
    color: $mayGreen;
  }
  100% {
    color: $castletonGreen;
  }
}

form {
  display: flex;
}

input:not(:placeholder-shown) {
  color: $castletonGreen;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

.logoContainer {
  width: calc(100% - 10px);
  text-align: center;
  padding-top: 15px;
  .appLogo {
    height: 55px;
  }
}

.footerContainer {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  .footerText {
    font-size: 8pt;
    color: $outerSpace;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: calc(100% - 5px);
  height: auto;
  background-color: rgba(255, 255, 255, 0.746);
  padding-bottom: 30px;
  border-radius: 10px;
  margin-top: 40px;
  text-align: center;
  @include respond-above(xl) {
    display: block;
  }
  .enrollmentTextTitle {
    font-size: 18px;
    font-weight: 500;
    padding: 30px 15px 0;
    color: $outerSpaceLite;
  }
  .enrollmentTextContainer {
    font-size: 15px;
    padding: 0 15px 15px;
    color: $outerSpaceDark;
  }
}

fieldset {
  border: none !important;
  text-align: left;
  margin: 0;
  padding: 0;
  width: 90%;
}

button:disabled {
  border: 1px solid $spanishGray;
  background: linear-gradient(to bottom right, $philippineSilver, $gray);
  color: $graniteGray;
  box-shadow: 0 0 0 0;
}

ul {
  list-style: none;
}

#root {
  position: relative;
  min-height: 100vh;
}

// mui datepicker styles
.MuiOutlinedInput-input {
  padding: 5px 0 2px !important;
  &::placeholder {
    color: $gray !important;
    opacity: 1 !important; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray !important;
  }
}
.MuiOutlinedInput-root {
  padding: 0 !important;
}
.MuiIconButton-root {
  svg {
    height: 20px !important;
    width: 20px !important;
    stroke: $davysGray !important;
    fill: $davysGray !important;
  }
}

// Timeout Modal
.timeout-modal {
  .model-content {
    overflow: hidden;
    margin: 15px auto;
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    background: none;
    @include respond-below(sm) {
      width: 100%;
    }
  }
}
