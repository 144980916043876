//Color Variables
$white: #fff;
$black: #000;
$dimGrey: #686868;
$yankeesBlue: #181949;
$carrotOrange: #ed9525;
$earthyellow: #e4ab61;
$jasperOrange: #d89d4f;
$philippineSilver: #adb3b6;
$gray: #7c7c7c;
$spanishGray: #999999;
$davysGray: #5a5a5a;
$castletonGreen: #0e5d36;
$quartz: #4b4b4b;
$darkSilver: #6e6e6e;
$cyanBlue: #3c77c3;
$darkCharcoal: #333333;
$charlestonGreen: #292929;
$raisinBlack: #242424;
$flashWhite: #f0f0f0;
$mayGreen: #3a9b3a;
$castletonGreen: #0e5d36;
$outerSpace: #464646;
$outerSpaceLite: #444444;
$outerSpaceDark: #333333;
$brandRed: #db2222;

// button color
$graniteGray: #666666;
