@import '../../styles/mixins';
@import '../../styles/variables';

.failIcon {
  font-size: 55px;
  width: 100%;
  color: $davysGray;
  animation: scale 2s ease infinite alternate;
  animation-iteration-count: 5;
  margin-top: 10px;
}
.submitTwoFactor {
  border-radius: 13px;
  padding: 5px 15px 5px 15px;
  border: none;
  background: linear-gradient(to bottom right, $dimGrey, $yankeesBlue);
  color: $white;
  font-size: 18px;
  width: 40%;
  height: 40px;
  cursor: pointer;
}
