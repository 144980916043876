@import '../../../styles/mixins';
@import '../../../styles/variables';

.model {
  background: rgba($color: #1d1d1d, $alpha: 0.86);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  .modelContents {
    position: relative;
    z-index: 2;
    width: 550px;
    border-radius: 8px;
    margin: 15px auto;
    height: 95vh;
    overflow-y: scroll;
    background-color: $white;
    overflow-x: hidden;
    @include respond-below(lg) {
      height: 88vh;
    }
  }
}
