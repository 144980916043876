@import '../../styles/mixins';
@import '../../styles/variables';

.enrollForm {
  display: flex;
  width: calc(100% - 5px);
  justify-content: center;
  .enrollFormContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: auto;
    background-color: rgba(255, 255, 255, 0.746);
    padding-bottom: 30px;
    border-radius: 10px;
    margin-top: 30px;
    .enrollTitle {
      height: auto;
      font-size: 26px;
      padding-top: 15px;
      font-weight: 400;
      margin-right: 20px;
      margin-left: 20px;
      text-align: center;
      background: -webkit-linear-gradient(rgb(75, 75, 75), rgba(0, 0, 0, 0.87));
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      bottom: 0;
      @include respond-below(md) {
        font-size: 18px;
      }
    }
    .enrollInputWrapper {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      .enrollInputIconContainer {
        display: flex;
        align-items: center;
        padding-right: 0;
        color: $davysGray;
        border-radius: 15px;
        border-style: none;
        background-color: white;
        height: 50px;
        font-size: 20px;
        width: 100%;
        .enrollInputs {
          width: 90%;
          height: 45px;
          box-sizing: border-box;
          margin-left: 15px;
          right: 0;
          padding-right: 0;
          border-style: none;
          font-size: 16px;
          background: transparent;
          &:focus {
            outline: none;
          }
        }
        .leftSpace {
          margin-left: 18px;
        }
      }
      .inputErrMsg {
        color: $brandRed;
        font-size: 12px;
        margin-left: 12px;
      }
    }
    .timeOutMessage {
      width: 100%;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkCharcoal;
        span {
          margin-left: 5px;
          font-weight: 600;
          font-size: 22px;
          color: $brandRed;
        }
      }
    }
    .enrollAgreeCheckboxContainer {
      display: flex;
      padding: 0 15px;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 80%;
      fieldset {
        width: auto;
        .enrollAgreeCheckbox {
          margin: 15px;
          height: 16px;
          width: 22px;
          border-style: none;
          outline: none;
        }
      }
      .enrollTermsDesc {
        font-size: 12px;
        color: $darkSilver;
        .loginTermsLink {
          font-size: 12px;
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: #3c77c3;
          cursor: pointer;
          padding: 0;
        }
      }
    }
    .spacerDiv {
      width: calc(100% - 15px);
    }
    .enrollContainer {
      width: 80%;
    }
  }
}

.enrollButton {
  border-radius: 15px;
  font-weight: 300;
  padding: 5px 25px;
  border: none;
  background: linear-gradient(to bottom right, $carrotOrange, $earthyellow);
  color: white;
  box-shadow: 0px 1px 2px 0px $darkCharcoal;
  font-size: 24px;
  width: 100%;
  height: 45px;
  margin-top: 15px;
  &:focus {
    background: linear-gradient(to bottom right, $carrotOrange, $jasperOrange);
    color: white;
    box-shadow: 0px 1px 2px 0px $darkCharcoal;
  }
  @include respond-below(md) {
    font-size: 15px;
  }
}
.modalWrapper {
  .modalHeader {
    padding: 30px 30px 0;
    @include respond-below(sm) {
      padding: 15px 15px 0;
    }
    h2 {
      margin: 0 0 25px;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: $darkSilver;
      @include respond-below(sm) {
        font-size: 20px;
        margin: 0 0 15px;
      }
    }
  }
  .modalBody {
    padding: 0 30px;
    @include respond-below(sm) {
      padding: 15px 15px 0;
    }
    h3 {
      margin: 0 0 15px;
      color: $darkSilver;
    }
    h5 {
      margin: 0 0 15px;
      color: $darkSilver;
    }
    p {
      font-size: 14px;
      margin: 0 0 15px;
      color: $darkSilver;
    }
    a {
      color: $darkSilver;
      text-decoration: none;
    }
  }
  .modalFooter {
    background-color: $white;
    position: sticky;
    padding: 15px 20px;
    bottom: 0;
    @include respond-below(sm) {
      padding: 10px;
    }
    .backButton {
      border-radius: 13px;
      padding: 10px 15px 5px 15px;
      border: none;
      background: linear-gradient(to bottom right, $dimGrey, $yankeesBlue);
      color: $white;
      font-size: 18px;
      width: 100%;
      height: 40px;
      margin: auto;
      display: block;
      cursor: pointer;
      @include respond-below(sm) {
        padding: 5px 15px;
      }
    }
  }
}
